import React, { useState } from "react";
import { Formik } from "formik";
import { object, ref, string } from "yup";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import axios from "axios";

import Spinner from "./Spinner";
import Alert from "./Alert";

export default function FormPasswordReset() {
  const [passChangeSuccess, setPassChangeSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const _handleModalClose = () => {
    setPassChangeSuccess(false);
  };

  const _renderModal = () => {
    const onClick = () => {
      setPassChangeSuccess(false);
    };

    return (
      <Alert
        isOpen={passChangeSuccess}
        onClose={_handleModalClose}
        handleSubmit={onClick}
        title="Password Reset"
        text={message}
        submitButtonText="Done"
      />
    );
  };

  const _handleSubmit = ({
    newPass,
    confirmPass,
    setSubmitting,
    resetForm,
  }) => {
    // fake async login
    const urlParams = new URLSearchParams(window.location.search);
    const queryToken = urlParams.get("token");
    console.log(newPass, confirmPass, queryToken);
    axios
      .post(
        `https://api.tipme-app.com/v1/auth/reset-password?token=${queryToken}`,
        {
          password: newPass,
        }
      )
      .then((res) => {
        setSubmitting(false);
        setMessage("Password Reset Successful. You can now login");
        setPassChangeSuccess(true);
        resetForm();
      })
      .catch((e) => {
        console.log(e);
        setMessage(e.response.data.message);
        setPassChangeSuccess(true);
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        newPass: "",
        confirmPass: "",
      }}
      validationSchema={object().shape({
        newPass: string().required("New password is required"),
        confirmPass: string()
          .oneOf([ref("newPass")], "Passwords do not match")
          .required("Password is required"),
      })}
      onSubmit={({ newPass, confirmPass }, { setSubmitting, resetForm }) =>
        _handleSubmit({
          newPass,
          confirmPass,
          setSubmitting,
          resetForm,
        })
      }
      render={(props) => {
        const {
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          handleSubmit,
          isValid,
          isSubmitting,
        } = props;
        return isSubmitting ? (
          <Spinner />
        ) : (
          <Paper className="form form--wrapper" elevation={10}>
            <form className="form" onSubmit={handleSubmit}>
              <FormControl
                fullWidth
                margin="dense"
                error={Boolean(touched.newPass && errors.newPass)}
              >
                <InputLabel
                  htmlFor="password-new"
                  error={Boolean(touched.newPass && errors.newPass)}
                >
                  {"New Password"}
                </InputLabel>
                <Input
                  id="password-new"
                  name="newPass"
                  type="password"
                  value={values.newPass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.newPass && errors.newPass)}
                />
                <FormHelperText
                  error={Boolean(touched.newPass && errors.newPass)}
                >
                  {touched.newPass && errors.newPass ? errors.newPass : ""}
                </FormHelperText>
              </FormControl>
              <FormControl
                fullWidth
                margin="dense"
                error={Boolean(touched.confirmPass && errors.confirmPass)}
              >
                <InputLabel
                  htmlFor="password-confirm"
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                >
                  {"Confirm Password"}
                </InputLabel>
                <Input
                  id="password-confirm"
                  name="confirmPass"
                  type="password"
                  value={values.confirmPass}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                />
                <FormHelperText
                  error={Boolean(touched.confirmPass && errors.confirmPass)}
                >
                  {touched.confirmPass && errors.confirmPass
                    ? errors.confirmPass
                    : ""}
                </FormHelperText>
              </FormControl>
              <Button
                type="submit"
                variant="raised"
                color="primary"
                disabled={Boolean(!isValid || isSubmitting)}
                style={{ margin: "16px" }}
              >
                {"Reset Password"}
              </Button>
            </form>
            {_renderModal()}
          </Paper>
        );
      }}
    />
  );
}
